/********************GLOBAL********************/
@font-face {
    font-family: 'sketch_matchregular';
    src: url('./assets/sketch_match-webfont.woff2') format('woff2'),
    url('./assets/sketch_match-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

* {
    background-color: #fbfbff;
    font-family: 'Zilla Slab', serif;
    font-size: 20px;
}

button {
    border: 1px solid black;
}

/********************NAVBAR********************/
.nav-bar, .navbar-brand{
    background-color: #72b01d;
}

.nav-bar div {
    background-color: #72b01d;
    margin-bottom: 0px;
    margin-right: 30px;
    padding: 5px;
}

.nav-bar img {
    width: 300px;
}

.nav-bar a {
    color: #fbfbff;
    font-size: 24px;
    text-shadow: 1px 1px rgb(0,0,0,.4);
}

.nav-bar a:hover {
    text-decoration: none;
    color: #499ddb;
}

.navbar-brand img {
    background-color: transparent;
}

@media (max-width: 576px) {
    .nav-bar div {
        margin-right: 30px;
    }

    .nav-bar img {
        width: 210px;
    }

    .nav-bar a {
        font-size: 15px;
    }
}

/********************HOME********************/

.sitename-container img{
    width: 80%;
}

.sitename-container {
    text-align: center;
    margin-top: 30px;
    display: flex;
    justify-content: center;
}

@media (max-width: 925px) {

    .sitename-container img {
        width: 130%;
    }
}

@media (max-width: 576px) {

    .sitename-container {
        margin-top: 30%;
    }
}
/********************MODALS********************/
span {
    font-family: serif;
}

input.btn.submit-button, button.btn.btn-primary {
    border: 1px solid black;
    background-color: white;
    color: black;
    margin: 8px;
}

input.btn.submit-button:hover, button.btn.btn-primary:hover {
    background-color: #499ddb;
}

.add-form input {
    margin-left: 8px;
}

.add-form label {
    margin: 15px;
    font-size: 22px;
}
