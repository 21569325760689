/* Random Page */

.function-box {
    width: 78%;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
}

.function-box label {
    margin-right: 5px;
    margin-bottom: 0;
}

#search_box {
    height: 50%;
    box-sizing: border-box;
    margin-right: 5px;
}

#filter_box {
    margin-right: 20px;
}

#options {
    display: flex;
    text-align: left;
    align-items: center;
    width: 80%;
}

#clear-random, #add-random {
    margin: 4px 0px;
    border: 1px solid black;
}


.random-page  {
    text-align: center;
    margin-top: 30px;
}

#add-box {
    margin-right: 5px;
}

#add-random {
    font-size: 20px;
}

#add-random-small {
    padding: 0 5px;
    line-height: 75%;
    margin: 4px 5px;
    font-size:30px;
    display: none;
    border: 1px solid black;
}


.boxpicture {
    margin: 5px;
    padding: 8px;
    width: 20%;
}

table.table {
    width: 79%;
    margin: auto;
}

.random-table div.btn {
    margin: 4px;
}


.loading {
    position: relative;
    top: 100px;
    left: 20px;
    background-color: transparent;
}

/* Randoms */
.random-name {
    font-weight: bold;
    text-align: left;
    width: 35%;
}

.random-details {
    width: 33%;
    text-align: center;
}

.random-buttons {
    text-align: right;
    display: flex;
    justify-content: flex-end;
    cursor: pointer;

}

.random-buttons div {
    margin: 0px 4px;
    border: 1px solid black;
    padding: 2px;
    font-size: 14px;
}

#edit-random, #delete-random  {
    font-size: 20px;
}

#edit-random-small, #delete-random-small {
    padding: 5px;
    line-height: 75%;
    margin: 4px;
    font-size: 15px;
    display: none;
}

.table td {
    vertical-align: middle;
}

@media (max-width: 970px) {

    .random-buttons div {
        font-size: 12px;
    }

    .random-name, .random-details {
        font-size: 16px;
    }

    .random-box {
        font-size: 16px;
    }

    #clear-random, #add-random, .function-box label, .function-box select {
        font-size: 14px;
        padding: 3px;
    }
}

@media (max-width: 810px) {
    #search_box {
        width: 120px;
        height: 60%;
    }
}

@media (max-width: 740px) {
    #options {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    #add-box {
        display: flex;
        align-items: flex-end;
        background-color: transparent;
    }

    #add-box button:focus {
        outline: none;
    }

    #search_box {
        width: 100px;
        font-size: 15px;
        height: 30px;
    }

    table.table, .function-box {
        width: 90%;
    }

}

@media (max-width: 576px) {
    #clear-random, #add-random {
        font-size: 12px;
    }

    #search_box {
        font-size: 10px;
    }

    .random-name, .random-details {
        font-size: 14px;
    }

    .random-box {
        font-size: 12px;
    }

    .dropdown, .dropleft, .dropright, .dropup {
        font-size: 14px;
    }

    #add-random, #edit-random, #delete-random {
        display: none;
    }

    #add-random-small, #edit-random-small, #delete-random-small {
        display: inline;
    }

    .add-form label {
        font-size: 80%;
    }

    .modal-header {
        padding: 10 20px;
    }

    .modal-header .h4 {
        font-size: 20px;
    }

    .modal-body button {
        font-size: 80%;
    }
}
