/* Needle Page */
.needles {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

/* Needles */
.needle-box {
    box-shadow: 3px 3px rgb(211,211,211,.6);
    background-color: white;
    outline-offset: -10px;
    margin: 5px;
    width: 250px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 5px;
}

.needle-box table, p {
    margin: auto;
}

.needle-box td, h1 {
    background-color: white;
}

/* Needle Edit */
.checkbox-label {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
}

/*Hide old checkbox*/
.checkbox-label input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 35px;
    width: 35px;
    background-color: #eee;
    margin-left: -5px;
}

/* On mouse-over, add a grey background color */
.checkbox-label:hover input ~ .checkmark {
    background-color: #ccc;
}

.checkbox-label input:checked ~ .checkmark {
    background-image: url('../../assets/favicon-32x32.png');
    background-repeat: no-repeat;
    background-color: white;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.checkbox-label input:checked ~ .checkmark:after {
    display: block;
}

.needle-buttons {
    display: flex;
    justify-content: center;
}

.loading {
    position: relative;
    top: 100px;
    background-color: transparent;
}


@media (max-width: 576px) {
    .needle-box{
        width: 160px;
    }

    .needle-box td {
        font-size: 70%;
    }

    .needle-box h1 {
        font-size: 30px;
    }

    .yarnball img{
        width: 120%;
    }

    .modal-header {
        padding: 10 20px;
    }

    .modal-header .h4 {
        font-size: 20px;
    }

    .modal-body button {
        font-size: 80%;
    }

    .modal-dialog modal-sm{
        width: 70%;
    }

    .needle-edit label {
        font-size: 80%;
    }

    .loading {
        font-size: 50%;
    }


}
