.zipper-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: 0px 20px;
}

.zipper-size {
    width: 17%;
    padding: 10px;
    margin-top: 20px;
    min-height: 440px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-right: 2px outset rgb(0,0,0,.3);
    box-sizing:content-box;
    text-align: center;
}


.new-zipper {
    margin-left: -20px;
}

#new-zipper-size {
    margin-left: 10px;
}

#add-box {
    display: flex;
    justify-content: flex-end;
    margin: 5px 20px;
}

#add-zipper-small {
    padding: 0 5px;
    line-height: 75%;
    margin: 4px 0px;
    font-size:30px;
    display: none;
}

.delete {
    margin-right: 2px;
    cursor: default;
}

.loading {
    position: relative;
    top: 100px;
    background-color: transparent;
}

@media (max-width: 1060px) {
    .new-zipper {
        font-size: 16px;
        width: 80%;
        margin-right: 0px;
    }

    .zipper-container h3 {
        font-size: 24px;
    }

    .zipper-container p {
        font-size: 16px;
    }

    .zipper-size {
        width: 20%;
        padding: 2px;
    }

    .add-zipper {
        margin: 5px 0px;
        font-size: 16px;
        width: 80%;
    }
}



@media (max-width: 675px) {
    .zipper-page {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .zipper-container h3{
        font-size: 20px;
    }

    .zipper-container p{
        font-size: 14px;
    }

    .zipper-size {
        width: 30%;
    }

    .new-zipper {
        width: 70%;
        font-size: 16px;
    }

    #add-zipper {
        display: flex;
        flex-direction: column;
        background-color: transparent;
    }
}

@media (max-width: 576px) {

    .loading {
        top: 40%;
        left: 40%;
        position: absolute;
        font-size:100%;
    }

    .dropdown, .dropleft, .dropright, .dropup {
        font-size: 14px;
    }

    #add-zipper {
        display: none;
    }

    #add-zipper-small {
        display: inline;
    }

    .add-form label {
        font-size: 60%;
    }

    .modal-header {
        padding: 10 20px;
    }

    .modal-header .h4 {
        font-size: 20px;
    }

    .modal-body button {
        font-size: 60%;
    }
}
